import logo from './logo.svg';
import './App.css';
import {useRef, useState} from 'react';

function App() {

  // 데이터가 변경되면 html 에 변경되는 코드를 짜야함
  let post = 'ReactBlog';
  // 데이터가 변경되어도 html 에 바로 적용됨(재랜더링)
  let [글제목, 글제목변경] = useState(['남자 코트 추천', '파이썬 독학', '맛집 추천'])
  let [따봉, 따봉변경] = useState([false, false, false]);
  let [modal, setModal] = useState(false);
  let [title, setTitle] = useState(0);
  let [입력값, 입력값변경] = useState('');

  return (
    <div className="App">
      <div className="black-nav">
        <h4 style={{color:'red', fontSize: '20px'}}>{post}</h4>
      </div>

      <button onClick={ ()=>{
          // [...글제목] 이거는 새로운 데이터 공간 생성, arry는 
          let copy = [...글제목];
          copy[0] = '여자 코트 추천';
          글제목변경(copy);
            } }>글 수정</button>
      <button onClick={ ()=>{
        let copy = [...글제목]
        copy.sort()
        글제목변경(copy)
      } }>가나다순 정렬</button>


      {
          글제목.map(function(a, i){
            return (
            <div className="list" key={i}>
              <h4 onClick={() => {
                // modal == false ? setModal(true) : setModal(false)
                setModal(!modal)
                setTitle(i)
                }}>
                  {a}
                  <span onClick={(e) => {
                    e.stopPropagation(); 
                    let copy = [...따봉];
                    copy[i] = copy[i] + 1;
                    따봉변경(copy) }}>👍</span> {따봉[i]}
              </h4>
            <p>2월 17일 발행</p>
            <button onClick={ ()=>{
              let copy = [...글제목];
              copy.splice(i,1);
              글제목변경(copy);
            } }>삭제</button>
            </div>
          )
          })
      }
      <input onChange={ (e)=>{ 
        입력값변경(e.target.value);
        console.log(입력값);
       } } />
       <button onClick={ ()=>{
         let copy = [...글제목];
         copy.unshift(입력값);
         글제목변경(copy);

         let 따봉copy = [...따봉];
         따봉copy.unshift(false);
         따봉변경(따봉copy);
       } }>추가</button>

      {
        // modal == true ? <Modal 작명={state이름}/> : null
        modal == true ? <Modal title={title} 글제목={글제목} 글제목변경={글제목변경}/> : null
      }

    </div>
  );
}

// const Modal = () => {}

function Modal(props) {
  // 큰 div 는 하나만
  return (
    <div className="modal" style={{background:props.color}}>
        <h4>{props.글제목[props.title]}</h4>
        <p>날짜</p>
        <p>상세내용</p>
        <button onClick={ ()=> {
          let copy = [...props.글제목];
          copy[0] = '여자 코트 추천';
          props.글제목변경(copy);
        }}>글 수정</button>
      </div>
  )
}

// class Modal2 extends React.Component {
//   constructor() {
//     super()
//   }
//   render() {
//     return (
//       <div>안녕</div>
//     )
//   }
// }

export default App;
